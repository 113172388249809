* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;

  span,
  div {
    font-feature-settings: 'liga' off;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}
img {
  max-width: 100%;
  object-fit: cover;
}
a, input, textarea, button {font-family: inherit}

.main_wrapper {
  position: relative;
  z-index: 0;
  min-height: 100vh;
  background: $bg-main;
  overflow: hidden;
}
.bg_wrapper {
  top: 0;
  left: calc(50% - 720px);
  position: absolute;
  z-index: 10;
  max-width: 1440px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  .bg_ellipses {
    position: absolute;
    top: 50%;
    left: -5%;
    width: 100%;
    height: 100%;
    z-index: 0;
    margin: 0 auto;

    &:before, &:after {
      content: "";
      z-index: 1;
      position: absolute;
      width: 620px;
      height: 620px;
      filter: blur(600px);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    &:before {
      background: #38308B;
      top: -101px;
      left: -94px;
    }
    &:after {
      background: #CA0B84;
      bottom: -170px;
      right: -135px;
    }
  }
}
.page_wrapper {
  position: relative;
  z-index: 10;
  max-width: 1440px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 73px;
}

@media (max-width: 768px) {
  .page_wrapper{
    padding: 0 35px;
  }
}

@media (max-width: 541px){
  .page_wrapper{
    padding: 0 15px;
  }
}