@import '/src/styles/variables';

@mixin h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 1.3;
  color: $text-color;
  @media (max-width: 541px) {
    font-size: 32px;
    line-height: 37px;
  }
}

@mixin  h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 1.3;
  color: $text-color;
  @media (max-width: 541px) {
    font-size: 28px;
    line-height: 32px;
  }
}
