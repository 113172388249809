$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

// Mobile first
$mediaWidthLaptop: 1440px;
$mediaWidthDesktop: 1024px;
$mediaWidthTablet: 768px;
$mediaWidthMobile: 550px;

$bg-main: #222B45;
$gray: #333333;
$color-accent: #DB0382;
$text-color: #FFFFFF;
$text-btn-color-white: #EDF1F7;
$btn-color: rgb(117, 121, 136);
$bg-btn-default: rgba($btn-color, 0.25);
$border-btn-default: rgba($btn-color, 0.3);
$color-another-interesting-white-color: #F0F0F0;
$bg-btn-filled-hover: #BD0471;
$text-btn-disabled: #F0F0F0;

$admin-modal-background: #FFFFFF;
$gray-color: rgb(183, 183, 183);
$gray-color-2: #edeaf4

