@import '@/styles/variables';
@import '@/styles/mixins';

.header_wrapper {
  padding: 40px 0;
  @include flex(row, center, space-between, nowrap);
  .logo {
    max-width: 131px;
  }
  .link {
    &Wrapper {
      @include flex(row, center, flex-end);
      margin-left: auto;
    }
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: $text-color;
    margin-right: 40px;
    position: relative;
    overflow: hidden;
    @include transition;
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color-accent;
      transform: translateY(2px);
      @include transition;
    }
    &.active {
      color: rgba($color-accent, 0.9);
      &:after {
        transform: translateY(0);
      }
    }
  }
  .connectWrapper {
    position: relative;
    width: 554px;
    @include flex(row, center, space-between, nowrap);
    .disconnect {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      @include transition;
      &.active {
        max-height: 200px;
      }
    }
    .balance {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      &Block {
        padding: 24px 33px;
        flex: 0 0 50%;
      }
      &Title {
        color: $gray-color;
      }
    }
  }
  .btn {
    padding: 24px 167px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: $text-btn-color-white;
    &_disconnected {
      padding: 24px 167px;
      font-size: 20px;
      letter-spacing: 0.015em;
      text-transform: uppercase;
      width: 100%;
    }
  }
  .btn_connected {
    padding: 12px 18px 12px 10px;
    @include flex(row, center, flex-start, nowrap);
    .icon {
      width: 53px;
      height: 48px;
      margin-right: 18px;
      @include flex(row, center, center);
      img {
        width: 100%;
      }
    }
    .text {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: none;
    }
    .mobileText {
      display: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: none;
    }
  }
}

@media (max-width: 1024px) {
  .header_wrapper {
    .connectWrapper {
      .balance {
        &Block {
          flex: 0 0 auto;
          padding: 24px 20px;
        }
      }
    }
  }
}

@media (max-width: 830px) {
  .header_wrapper {
    @include flex(column, center, flex-start, nowrap);
    .link {
      &Wrapper {
        margin: 10px 0;
        @include flex(row, center, flex-end);
        margin-left: 0;
      }
      margin-right: 0;
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
    .connectWrapper{
      width: 100%;
      @include flex(column-reverse, center, flex-start, nowrap);
      .balance{
        &Block{
          width: 100%;
        }
      }
      .btn_connected{
        width: 100%;
        margin-bottom: 16px;
        @include flex(row, center, center, nowrap);
      }
    }
    .btn_connected {
      margin-top: 20px;
      min-width: 0;
      .text {
        display: none;
      }
      .mobileText {
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 541px) {
  .header_wrapper {
    .btn {
      margin-top: 10px;
      width: 100%;
      padding: 24px 0;
    }
    .connectWrapper {
      .btn_connected {
        .icon {
          margin-right: 12px;
        }
      }
    }
  }
}

@media (max-width: 830px) {
  .header_wrapper {
    @include flex(column, center, flex-start, nowrap);
    .link {
      &Wrapper {
        margin: 10px 0;
        @include flex(row, center, flex-end);
        margin-left: 0;
      }
      margin-right: 0;
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
    .btn_connected {
      margin-top: 20px;
      min-width: 0;
      .text {
        display: none;
      }
      .mobileText {
        display: inline-block;
      }
    }
  }
}
