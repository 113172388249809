@import 'src/styles/variables';
@import 'src/styles/mixins/helpers.scss';

.pagin {
  width: 2.5rem;
  height: 2.5rem;
  background: transparent;
  border-radius: 50%;
  color: $text-color;
  padding: 0;
  border: 1px solid transparent;
  @include transition;

  &.active {
    border-color: $text-color;
  }
}

.remove {
  padding: 0.75rem 2.5rem;
  background: transparent;
  border: 1px solid $text-color;
  transition: 0.4s ease;
  color: $text-color;
  border-radius: 1.5rem;
  font-size: 1.125rem;

  &:hover {
    background: $text-color;
    color: $color-accent;
  }

  &Cell {
    text-align: end;
  }
}

.wrapper {
  margin-top: 4rem;
  width: 100%;
  @include flex(column, flex-start, flex-start, nowrap);

  .scrollableTable {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    color: $text-color;
    font-size: 1.125rem;

    thead {
      font-weight: 600;
      border-bottom: 1px solid $text-color;
      tr {
        td {
          color: rgba($text-color, 0.6);
          padding: 0 0.625rem 1.5625rem;
          &.type{
              min-width: 110px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 1.25rem 0.625rem;

          &.amount{
            svg{
              width: 24px;
              height: 24px;
            }
          }

          &.address {
            @include ellipsis;
            @media (max-width: 541px) {
              max-width: 150px;
            }
          }
        }
      }
    }
  }

  .pagination {
    @include flex(row, center, space-between, nowrap);
    gap: 10px;
    width: 100%;
    margin-top: 20px;
    @media (max-width: 541px) {
      @include flex(column, center, flex-start, nowrap);
      padding-bottom: 1.25rem;
    }

    .paginator {
      flex: 0 0 50%;
    }

    .bal {
      text-align: end;
      line-height: 1.2;
    }
  }

  .balance {
    color: $text-color;
    font-size: 18px;
    letter-spacing: 2px;

    &.low {
      color: $color-accent;
    }
  }

  .baseData {
    margin-top: 20px;
    color: $text-color;
    font-size: 14px;
    letter-spacing: 2px;
    width: 100%;
    padding: 0 0.625rem 1.5625rem;
    @include flex(row, flex-start, flex-start);
    &Warning{
      max-width: 24px;
      max-height: 24px;
      margin-right: 10px;
      & svg{
        width: 100%;
        height: 100%;
      }
    }
  }
}

.editable {
  cursor: pointer;
  @include transition;
  &Wrapper {
    position: relative;
  }
  &Field {
    color: $text-color;
    background: $bg-btn-default;
    border: 0px solid transparent;
    @include transition;
    position: absolute;
    display: inline-block;
    z-index: -1;
    opacity: 0;
    max-width: 100%;
    top: 0;
    left: 0;
    &.active {
      padding: 5px 10px;
      top: -25px;
      left: calc(50% - 5px);
      transform: translateX(-50%);
      z-index: 1;
      opacity: 1;
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    .baseData {
      text-align: center;
      @include flex(column, center, center);
    }
  }
}

@media (max-width: 541px) {
  .wrapper {
    .balance {
      font-size: 14px;
    }
  }
}
