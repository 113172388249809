@import 'media';
@import 'global.scss';

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: 'woff') {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $format == 'woff' {
      src: url('#{$file-path}.#{$format}') format($format);
    } @else {
      src: url('#{$file-path}.#{$format}') format('truetype');
    }
  }
}

@mixin flex($direction: row, $alignment: normal, $justification: normal, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justification;
  align-items: $alignment;
  flex-wrap: $wrap;
}

@mixin grid($columns: repeat(2, 1fr), $rows: 1fr, $gap: 10px) {
  display: grid;
  grid-template-columns: $columns;
  grid-auto-rows: $rows;
  gap: $gap;
}

@mixin text-control(
  $size: 14px,
  $height: 15px,
  $weight: normal,
) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
}

$transition-duration: .3s;
$transition-timing-function: ease;
$transition-delay: 0s;

@mixin transition($duration: $transition-duration, $function: $transition-timing-function, $delay: $transition-delay, $property: all){
  transition: $property $duration $function $delay;
}

@mixin ellipsis {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
