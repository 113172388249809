@import '@/styles/mixins';
@import '@/styles/variables';

.vesting_wrapper {
  background: $bg-btn-default;
  border: 1px solid $border-btn-default;
  box-sizing: border-box;
  border-radius: 32px;
  padding: 48px 0 34px;
  width: 100%;
  @include flex(column, center);
  min-width: 529px;
  height: fit-content;

  .count {
    font-weight: 300;
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 30px;
    span {
      margin-left: 5px;
      font-size: 30px;
      line-height: 32px;
    }
  }
  .timer_tracking {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 42px;
  }

  .balance {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 22px;
    color: $gray-color-2;
    opacity: 0.7;
  }

  .claim {
    &_all {
      width: 100%;
      padding: 6px 25px;
      margin-bottom: 22px;
    }
  }

  .vesting_stats {
    width: 100%;
    @include flex(row, center, space-evenly, nowrap);
    .vesting_single_stat {
      @include flex(column, center, flex-start, nowrap);
    }
  }

  .stages {
    @include flex(column, space-between, flex-start, nowrap);
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 25px;
    width: 100%;
    .stage {
      width: 100%;
      background: $bg-btn-default;
      border: 1px solid $border-btn-default;
      border-radius: 10px;
      padding: 10px 10px 9px 16px;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
      @include flex(row, center, space-between, nowrap);
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      .mobileLabel {
        display: none;
      }
      &_btn {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        flex: 0 0 auto;
        padding: 6px 24px;
        @include ellipsis;
      }
    }
  }
}

@media (max-width: 541px) {
  .vesting_wrapper {
    padding: 48px 0 34px;
    min-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    border: 0px solid transparent;
    background: transparent;
    .vesting_stats {
      @include flex(column, center, flex-start, nowrap);
      .vesting_single_stat {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    .balance {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .count {
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 48px;
      line-height: 58px;
    }
    .timer_tracking {
      font-size: 20px;
      line-height: 24px;
    }
    .claim {
      padding: 0 18px;
    }
    .stages {
      .stage {
        font-size: 16px;
        line-height: 19px;
        padding: 20px 30px;
        width: 100%;
        @include flex(column, flex-start, flex-start);
        &_btn {
          margin-top: 16px;
          font-size: 16px;
          line-height: 19px;
          padding: 9px 20px;
          flex: 0 0 auto;
          width: 100%;
        }
        &_number {
          margin-bottom: 16px;
        }
        &_timer {
          margin-bottom: 16px;
        }
        .mobileLabel {
          color: $gray-color-2;
          opacity: 0.5;
          display: inline-block;
          margin-bottom: 4px;
        }
      }
    }
  }
}
