@import '/src/styles/mixins/helpers.scss';

.limits {
  margin: 0 auto;
  max-width: 550px;
  @include flex(column, center, flex-start);
  .left {
    background: $bg-btn-default;
    border: 1px solid $border-btn-default;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 7.5px 20px;
    width: 100%;
    margin: 20px auto;
    @include flex(column, center, center);
    p {
      text-align: center;
      &.left {
        &Title {
          font-size: 16px;
          line-height: 24px;
        }
        &Amount{
          margin: 10px 0;
        }
      }
    }
    &Mint{
      font-size: 16px;
      line-height: 20px;
      font-weight: normal;
      padding: 7.5px 15px;
    }
  }
  &Main {
    margin-top: 30px;
    width: 100%;
    @include flex(row, flex-start, space-evenly);
    .pie {
      @include flex(column, center, flex-start);
    }
    .inputs {
      @include flex(column, flex-start, flex-start);
      .input {
        margin-bottom: 36px;
      }
    }
    .save {
      font-size: 18px;
      padding: 5px 20px;
    }
  }
}

@media screen and (max-width: 541px) {
  .limits {
    max-width: 100%;
    padding: 0 10px;
    &Main {
      @include flex(column, center, flex-start);
      .inputs {
        width: 100%;
        .input {
          width: 100%;
          margin-bottom: 36px;
        }
      }
      .save{
          margin-bottom: 40px;
      }
    }
  }
}
